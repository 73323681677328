.stats-perform {
  display: block;
  text-align: left;
}

.stats-perform-logo-container {
  background: black;
  border-bottom: solid white 2px;
}

.stats-perform-logo {
  max-height: 112px;
  max-width: 312px;
  width: 50vw;
  display: inline-block;
}
