.one-col-section {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  height: 100%;
  width: 80%;
  margin: auto;
}

.two-col-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  height: 100%;
  width: 80%;
  margin: auto;
}

.three-col-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  grid-auto-flow: row;
  height: 100%;
  width: 80%;
  margin: auto;
}

.section-header {
  position: sticky;
  top: 0;
}

.section {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.section-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media (max-width: 700px) {
  .two-col-section,
  .three-col-section {
    grid-template-columns: 1fr;
  }
}
