.pigs-title {
  background: #f6a1d5;
  border-bottom: solid lightgray 2px;
  font-size: 48px;
}

.pigs-background {
  background-size: cover;
  background: url("../assets/images/Pigs.png") no-repeat fixed center center;
}

.pigs-container {
  min-height: 100vh;
}

.pigs-content {
  font-size: 36px;
  padding: 32px;
}

.pigs-play-button {
  background: black;
  width: fit-content;
  display: inline-block;
  padding: 16px 32px;
  margin: 32px auto;
  border-radius: 8px;
}

.pigs-play-button:hover {
  background: gray;
  cursor: pointer;
}

.pigs-row {
  margin-top: 32px;
}