.volleyball {
  font-size: 36px;
  font-variant: small-caps;
  padding: 48px 8px;
  font-weight: bold;
}

.loyola-logo {
  height: 100px;
}

.loyola-logo-container {
  background: #592c40;
}

.volleyball-background {
  background-size: cover;
  background: url("../assets/images/Background.jpg") no-repeat fixed 50% top;
}
