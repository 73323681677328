.profile-icon {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 4px;
  border: solid 4px white;
  background-color: white;
  display: inline-block;
  bottom: 0;
}

.profile-icon:hover {
  cursor: pointer;
  border: solid 4px lightblue;
}

.profiles {
  display: inline-block;
  float: right;
  margin: 16px;
  bottom: 0;
  z-index: 1;
}
