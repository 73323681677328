.aws-title {
  background: white;
  border-bottom: solid lightgray 2px;
}

.aws-cert-container {
  padding: 16px 0;
}

.aws-certs-logo {
  max-height: 100px;
  width: 70vw;
  max-width: 352px;
}

.aws-cert {
  max-height: 400px;
  max-width: 70vw;
}
