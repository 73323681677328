@import "Styling/AboutMe.css";
@import "Styling/AdOnTheMove.css";
@import "Styling/Aws.css";
@import "Styling/Music.css";
@import "Styling/Nim.css";
@import "Styling/Pigs.css";
@import "Styling/Profiles.css";
@import "Styling/Robinhood.css";
@import "Styling/Scroll.css";
@import "Styling/Sections.css";
@import "Styling/Slideshow.css";
@import "Styling/StatsPerform.css";
@import "Styling/Volleyball.css";
@import "Styling/Stoak.css";

.App {
  text-align: center;
  font-family: "GE Insp", monospace;
  color: white;
  font-size: x-large;
}

body {
  background: black;
}

h1 {
  font-weight: normal;
  font-size: 42px;
  margin-right: 0;
  display: inline-block;
  margin-bottom: 16px;
  padding-left: 24px;
  text-transform: capitalize;
  font-variant: small-caps;
}

.section-background {
  position: relative;
  position: sticky;
  top: 0;
  z-index: -1;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.section-background.img,
.section-background.video {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sections {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-auto-flow: row;
  grid-auto-rows: minmax(100vh, auto);
  margin: auto;
  grid-column-start: 2;
}

.sections-container {
  display: inline-block;
  width: 100%;
}

.cool-text {
  background: linear-gradient(45deg, #f3c278 40%, #af4261 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(45deg, #f3c278 40%, #af4261 60%);
  border-image-slice: 1;
}

@media (max-width: 1000px) {
  .app-grid {
    grid-template-columns: auto;
  }

  .app-content {
    grid-column-start: auto;
  }
}
