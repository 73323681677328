.cube-container {
  display: inline-block;
  height: 250px;
  width: 1px;
  margin-left: -50%;
}

.aotm-logo-container {
  background-color: black;
  border-bottom: solid lightgray 2px;
  height: 64px;
  z-index: 100;
}

.aotm-logo {
  width: 400px;
  display: block;
  margin: auto;
  object-fit: cover;
  object-position: -16px -80px;
}
