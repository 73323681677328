.music-link {
  display: inline-block;
  margin: auto;
  cursor: pointer;
}

.music-link a,
.music-link a:visited {
  text-decoration: none;
  color: rgb(98, 209, 246);
}

.music-link a:hover {
  color: white;
}

.music-equalizer {
  margin: auto;
  display: block;
}
