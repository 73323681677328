.about-me {
  vertical-align: top;
  text-align: left;
  width: fit-content;
}

.about-me-container {
  display: inline-block;
  min-height: 100vh;
}

.headshot-container {
  vertical-align: top;
  float: left;
  height: max-content;
  margin-right: 10px;
  margin-top: 10px;
}

.headshot {
  max-width: 360px;
  max-height: 360px;
  object-fit: cover;
  object-position: 100% 0;
  border-radius: 24px;
  width: 100%;
}

.greeting {
  font-size: xx-large;
  position: sticky;
  top: 0;
  background: black;
  width: 100vw;
  margin-left: -10vw;
  border-bottom: solid lightgray 2px;
  height: 48px;
}

.line {
  font-size: large;
  padding: 24px;
}
