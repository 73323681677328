.robinhood-logo-container {
  background: white;
  height: 83px;
  border-bottom: solid lightgray 2px;
}

.robinhood-logo {
  max-height: 112px;
  max-width: 312px;
  display: inline-block;
}
