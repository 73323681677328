.scheduler-link {
  display: inline-block;
  margin: auto;
  cursor: pointer;
}

.scheduler-link a,
.scheduler-link a:visited {
  text-decoration: none;
  color: rgb(74, 125, 238);
  font-weight: 700;
  text-shadow: black 2px 2px;
}

.scheduler-link a:hover {
  color: white;
}

.stoak-logo {
  width: 200px;
}

.stoak-logo-container {
  background: black;
}
